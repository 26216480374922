@font-face {
  font-family: "cocon-regular";
  src: url("./assets/fonts/CoconProRegular.ttf") format("opentype");
}
@font-face {
  font-family: "cocon-light";
  src: url("./assets/fonts/Cocon-Light.ttf") format("opentype");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .striked-background-diagonal {
    background-image: repeating-linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.1),
      /* Line color with transparency */ rgba(0, 0, 0, 0.1) 2px,
      /* Line width */ transparent 2px,
      transparent 10px /* Space size */
    );
  }
}

.vertical-text {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: rotate(180deg); /* Adjust rotation if needed */
  white-space: nowrap;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.ripple {
  position: relative;
  overflow: hidden;
}

.ripple::after {
  content: "";
  position: absolute;
  top: 20px;
  left: 50%;
  width: 0;
  height: 0;
  background: #fff; /* Adjust color here */
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: width 0.3s ease, height 0.3s ease, opacity 0.3s ease;
  mix-blend-mode: difference;
}

.ripple:active::after {
  width: 40px;
  height: 40px;
  opacity: 0;
  transition: width 0.3s ease, height 0.3s ease, opacity 0.3s ease;
}

.spin-vertically {
  animation: spin-vertically 2s linear infinite;
}
